@font-face {
  font-family: 'icomoon';
  src:  url(./fonts/icomoon.f9e8ce07a224291ea4ce8c4f5d1ba934.eot);
  src:  url(./fonts/icomoon.f9e8ce07a224291ea4ce8c4f5d1ba934.eot#iefix) format('embedded-opentype'),
    url(./fonts/icomoon.1f394eb842cb44cdb7b2ea73be06e1d6.ttf) format('truetype'),
    url(./fonts/icomoon.4664837996b2cf9b72e9d9be4abe21ac.woff) format('woff'),
    url(./fonts/icomoon.ed6a3cb7857bd3bd7fcfee079aff8da7.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-group-131:before {
  content: "\e931";
}
.icon-group-13:before {
  content: "\e906";
}
.icon-accueil:before {
  content: "\e900";
}
.icon-affaire:before {
  content: "\e901";
}
.icon-Agenda:before {
  content: "\e902";
}
.icon-alerte:before {
  content: "\e903";
}
.icon-anomalies-full:before {
  content: "\e904";
}
.icon-article:before {
  content: "\e907";
}
.icon-business:before {
  content: "\e908";
}
.icon-chantier:before {
  content: "\e909";
}
.icon-check:before {
  content: "\e90a";
}
.icon-check_2:before {
  content: "\e90b";
}
.icon-coef:before {
  content: "\e90c";
}
.icon-contact:before {
  content: "\e90d";
}
.icon-date:before {
  content: "\e90e";
}
.icon-Edit:before {
  content: "\e90f";
}
.icon-entreprise:before {
  content: "\e910";
}
.icon-filtres:before {
  content: "\e911";
}
.icon-liste-affaires:before {
  content: "\e912";
}
.icon-livraison:before {
  content: "\e913";
}
.icon-marge-pourcentage:before {
  content: "\e914";
}
.icon-marge-valeur:before {
  content: "\e915";
}
.icon-montant:before {
  content: "\e916";
}
.icon-note:before {
  content: "\e917";
}
.icon-PA-UNITAIRE-HT:before {
  content: "\e918";
}
.icon-PV-APRES-REMISE-HT:before {
  content: "\e919";
}
.icon-PV-TOTAL-APRES-REMISE-HT:before {
  content: "\e91a";
}
.icon-PV-UNITAIRE-HT:before {
  content: "\e91b";
}
.icon-quantites:before {
  content: "\e91c";
}
.icon-relances:before {
  content: "\e91d";
}
.icon-reminder:before {
  content: "\e91e";
}
.icon-remise:before {
  content: "\e91f";
}
.icon-technico-commercial:before {
  content: "\e920";
}
.icon-utilisateur:before {
  content: "\e921";
}
.icon-adduser:before {
  content: "\e922";
}
.icon-agenda:before {
  content: "\e923";
}
.icon-burger:before {
  content: "\e924";
}
.icon-call:before {
  content: "\e925";
}
.icon-chevron-gauche:before {
  content: "\e926";
}
.icon-edit:before {
  content: "\e927";
}
.icon-exit:before {
  content: "\e928";
}
.icon-filter:before {
  content: "\e929";
}
.icon-lock:before {
  content: "\e92a";
}
.icon-logout:before {
  content: "\e92b";
}
.icon-map-marker:before {
  content: "\e92c";
}
.icon-more:before {
  content: "\e92d";
}
.icon-anomalies:before {
  content: "\e92e";
}
.icon-ref-commande:before {
  content: "\e92f";
}
.icon-unlock:before {
  content: "\e930";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-notifications:before {
  content: "\e905";
}

.icon-check_2 {
  font-size: 10px;
  display: flex;
  align-items: center;
}
